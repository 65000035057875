.eventViewContainer {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}
.eventViewHeader {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #4C5962;
}
.eventTextIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.eventViewHeader > div > svg {
  margin-left: 32px;
  font-size: 2em;
  color: whitesmoke;
}
.eventViewHeader > div > p {
  margin: 0;
  margin-left: 12px;
  font-weight: bold;
  font-size: larger;
  color: whitesmoke;
}
.eventViewContent {
  height: 90%;
  border-radius: 8px;
  padding: 32px;
  overflow-y: auto;
}

.eventCardDash {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom right, var(--main-light-grey-color), var(--main-dark-grey-color));
  border-radius: 8px;
  height: 200px;
  width: auto;
}
.eventCardDash > img {
  /* padding: 8px; */
  object-fit: cover;
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 4px solid whitesmoke;
}
.eventCardDash > p {
  font-weight: bold;
  font-size: larger;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  color: whitesmoke;
}

.eventHeadingSelect {
  width: 100%;
  height: 33.59px;
  text-align: center;
  font-size: 1.8em;
}

.eventTitle {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 0em;
}
.eventText {
  
}

.errorText {
  text-align: center;
}

.eventNameText {
  vertical-align: middle;
}

.eventListCard {
  width: 100%;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.eventCardInfo {
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.eventCardButton {
  flex-shrink: 1;
}

.createEventButton {
  background-color: #4C5962 !important;
}
.createEventButton:hover {
  background-color: rgb(52, 61, 66) !important;
}


@media only screen and (max-device-width: 1150px) {
  .eventViewContent {
    padding: 12px;
  }
  .eventHeadingSelect {
    font-size: 1em;
  }
}