.biggerCardContainer {
    text-align: center;
    height: fit-content;
    padding-left: 24px;
    padding-right: 24px;
}

.cardContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-template-rows: min-content;
    grid-column-gap: 16px;
    grid-row-gap: 32px;
    justify-content: center;
    align-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
    height: fit-content;
}

.cardOrg {
    position: static;
    width: 210px;
    height: fit-content;
    overflow: hidden;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.cardOrg:hover .starContainer > .starRing {
    opacity: 1;
    transform: scale(1, 1);
}
.cardOrg:hover > .starContainer {
    background-color: rgb(35, 42, 46);
}
.cardImageContainer {
    height: 150px;
    width: 100%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    padding: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.cardImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
    /* border-radius: 8px; */
    /* overflow: hidden; */
}
.cardBottom {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.headerText {
    height: 4em;
    padding: 8px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.headerText > span {
    display: inline-block;
    text-align: center;
    font-size: larger;
    /* font-weight: bold; */
    color: #040404;
}
.footerText {
    text-align: center;
}
.footerText > span {
    display: inline-block;
    width: 100%;
    font-size: small;
    font-weight: bold;
    color: whitesmoke;
    background-color: var(--main-bg-color);
    /* border-bottom-left-radius: 8px; */
    /* border-bottom-right-radius: 8px; */
    padding-top: 8px;
    padding-bottom: 8px;
    margin-bottom: 0;
}

.descText {
    text-align: center;
}

.starContainer {
    position: absolute;
    width: 210px;
    height: 150px;
    /* border-radius: 8px; */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease-in-out;
    transition: background-color 0.4s;
}
.starRing {
    height: 75px;
    width: 75px;
    opacity: 0;
    transform: scale(0.9, 0.9);
    transition: ease-in-out;
    transition: opacity 0.6s, transform 0.6s;
}


.orgInfoCard {
    font-family: proximaNova;
    margin: auto;
    margin-bottom: 32px;
}

.organisationViewContainer {
    height: 100%;
    width: 100%;
    /* background-color: whitesmoke; */
}

.orgInfoIcons {
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.orgViewLink {
    width: fit-content;
    margin: auto;
    cursor: pointer;
}
.orgViewLink:hover {
    text-decoration: none;
}

.backButtonOrgView {
    margin: auto;
    margin-bottom: 20px;
}

.modal-90w {
    width: 90vw;
}

.headingContainer {
    text-align: center;
}

.heading {
    margin-top: 8px;
    margin-bottom: 12px;
    font-family: proximaNova;
    font-weight: bold;
}

.titleLine {
    color: black;
    width: 75%;
    margin-top: 0PX;
}

.column {
    margin-left: 12px;
    margin-right: 12px;
}



.searchIcon1 {
    margin-right: 4px;
}

.searchBarContainer1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px darkblue;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: fit-content;
    font-family: proximaNova;
    background-color: white;
}

.searchBar1 {
    border: none;
    font-size: 150;
    vertical-align: middle;
    /* background-color: whitesmoke; */
}

.filterIcon1 {
    border: none;
    font-size: 150;
    vertical-align: middle;
}

.filterSelect1 {
    border: none;
    padding: 0;
    padding-left: 12px;
    font-family: proximaNova;
}

select#xyz {
    border:0px;
    outline:0px;
 }

.filterSelect1 select {
    border: none;
    width: 100%;
    /* background-color: whitesmoke; */
}

.orgInfoImage {
    height: 300px;
    object-fit: scale-down;
}

.orgInfoCardContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    background-color: whitesmoke;
}

@media only screen and (min-width: 600px) {
    .orgInfoCard {
        text-align: center;
        width: 70%;
        margin-top: 32px;
    }
}

@media only screen and (max-width: 1150px) {
    .orgInfoCard {
        display: inline-block;
        margin: auto;
        margin-top: 8px;
        text-align: center;
        width: 90%;
    }   
    .cardOrg {
        max-width: 80vw;
    }
    .invisButton {
        width: fit-content;
    }
}