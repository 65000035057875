.eventListContainer {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg-color-transparent);
  backdrop-filter: blur(6px);
}

.eventItemList {
  padding-top: 1em;
}
.eventItemList:last-child {
  margin-bottom: 8px;
}
.eventItem {
  cursor: pointer;
  padding: 0.5em;
  margin-left: 0.5em;
  margin-right: 0.5em;
  margin: 4px 0.5em;
  border-radius: 8px;
  height: 72px;
}
.eventItem:hover:not(.active) {
  background-color: var(--main-bg-light-color) !important;
}
.eventItem:hover p {
  color: whitesmoke;
}
.eventItem.active {
  transition: background-color ease 0.5s;
  background-color: var(--main-bg-dark-color);
}

.searchBarSpace {
  margin: 0;
background-color: whitesmoke;
}


.list-view-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
}

.list-left {
  width: 64px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.list-left-image {
  height: 3.5rem;
}

.list-right {
  flex: 1;
}

.searchBarContainer {
  height: fit-content;
  padding-left: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-right: 8px;
  width: auto;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.searchIcon {
  display: block;
  border: none;
  color: whitesmoke;
}
.textInputMap {
  border: none;
  flex: 1;
  padding: 0;
  padding-left: 12px;
  background-color: rgba(255, 255, 255, 0);
  /* backdrop-filter: blur(2px); */
  color: white;
}

.searchBarMiddleGap {
  margin: 0;
  width: 100%;
  background-color: whitesmoke;
}

.mapModeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.mapModeButton > button {
  font-weight: bold;
  color: whitesmoke;
  margin-left: 16px;
  margin-right: 4px;
}
.mapModeButton > button:hover {
  background-color: var(--main-bg-color-transparent);
}
.mapModeButton > button.active {
  background-color: var(--main-bg-dark-color);
}


.mapSettingsButton {
  color: whitesmoke;
  height: 40px;
  padding: 0 0.4em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.mapSettingsButton > * {
  margin: 0;
}
.mapSettingsButton > svg {
  margin-right: 8px;
}
.mapSettingsButton > svg .fa-primary {
  color: var(--main-yellow-color);
  opacity: 0.8;
}
.mapSettingsButton > svg .fa-secondary {
  color: var(--main-blue-color);
  opacity: 1;
}
.mapSettingsButton:hover {
  color: white;
  border-radius: 8px;
  background-color: var(--main-bg-dark-color);
}

.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  justify-content: center;
  align-items: flex-start;
}
.filterSelect {
  border: none;
  padding: 0;
  width: 100%;
}
.filterIcon {
  border: none;
  vertical-align: middle;
  margin-right: 8px;
  color: whitesmoke;
}
.filterSelect select {
  appearance: none;
  border: 1px solid whitesmoke;
  background-color: rgba(255, 255, 255, 0);
  color: whitesmoke;
  border-radius: 8px;
  padding: 0.2em 0.8em;
  margin-bottom: 8px;
  width: 100%;
}

.eventListTitle {
  margin-bottom: 0;
  font-weight: bold;
}
.eventListDate {
  margin-bottom: 0;
  font-size: 0.9em;
}
