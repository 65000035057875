.profileContainer {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
}

.profileHeader {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F47E2E;
}
.profileTextIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profileHeader > div > svg {
  margin-left: 32px;
  font-size: 2em;
  color: whitesmoke;
}
.profileHeader > div > p {
  margin: 0;
  margin-left: 12px;
  font-weight: bold;
  font-size: larger;
  color: whitesmoke;
}

.profileContent {
  position: relative;
  height: 90%;
  border-radius: 8px;
  /* padding: 32px; */
  overflow-y: scroll;
}

@media only screen and (max-device-width: 1150px) {
  .profileContent {
    padding: 12px;
  }
}

@media only screen and (min-device-width: 1150px) {
  .profileContent {
    padding: 32px;
  }
}

.profileTitle {
  font-weight: bold;
  font-size: medium;
  margin-bottom: 0em;
}
.profileText {
  
}
.profileContent > button {
  margin-bottom: 1rem;
}

.pCreatedAccountOn {
  margin-top: 1em; 
}

.profileVerifyButton {
  background-color: var(--main-orange-color) !important;
  font-weight: bold;
}
.profileEditButton:hover {
  
}

.profileEditButton {
  position: absolute;
  top: 8px;
  right: 8px;
}