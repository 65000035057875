.mapContainer {
  height: 100%;
  width: 100%;
}

.mapContainerInfo {
  height: 200px;
  width: 100%;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.styleToggle {
  width: 29px;
  height: 29px;
  position: absolute;
  background-color: white;
  padding: 4px;
  padding-top: 3px;
  z-index: 2;
  margin-top: 1rem;
  margin-left: 1rem;
  border-radius: 5px;
  font-size: 100%;
  font-family: inherit;
  box-shadow: 0 0 0 2px rgba(0,0,0,.1);
}

.styleToggle:active {
  background-color: rgb(138, 138, 138);
}

.styleToggle:hover {
  background-color: #F2F2F2;
}

.dontShow {
  display: none;
}

@media only screen and (max-width: 600px) {
  .mapboxgl-ctrl-geocoder--input {
    width: 30%
  }
}

.imageContainerMap {
  width: 100px;
  height: 100px;
}

.imgMap {
  width: 100px;
  height: 100%;
  text-align: center;
  object-fit: cover;
}

.popupText {
  margin-bottom: 5px;
}
.popupHeader {
  margin-bottom: 3px;
}

.mainPopup {
  padding: 0;
}
