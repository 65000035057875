.navBar {
  background-color: rgb(35, 42, 46);
  font-family: proximaNova;
  font-weight: bold;
}

.navBarHome {
  /* background-color: rgba(35, 42, 46, 0.63); */
  z-index: 10;
  padding: 50px 100px !important;
  height: 8vh;
  font-family: proximaNova;
  font-weight: bold;
}

.mcLogo {
  height: 2rem;
}
.mcLogoHome {
  height: 2.5rem;
}

.spaceLink {
  margin-right: 20px;
}

.navlink {
  color: rgb(190, 190, 190);
  text-decoration: none;
}
.navlinkHome {
  color: white;
  text-decoration: none;
}
.navlink:active {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.navlink:hover {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.navlinkHome:active {
  color: white;
}
.navlinkHome:hover {
  color: white;
}

.loginNav {
  color: #222A2E;
  background-color: #BEBEBE;
  cursor: pointer;
  /* border: 1px solid white; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  width: fit-content;
}
.loginNav:hover {
  background-color: white;
  color: #222A2E;
  text-decoration: none;
}
.loginNavHome {
  background-color: white;
}

.loginNavLink:hover {
  text-decoration: none;
}

.navBarLogo {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}
.navBarLogo span {
  display: inline-block;
  margin-bottom: 0;
  font-size: small;
  font-weight: bold;
  margin-left: 8px;
  color: #222A2E;
  background-color: whitesmoke;
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

@media only screen and (max-width: 1150px) {
  .navBarHome {
    padding: 0.5em 1em !important;
  }
}

