.groupMainContainer {
  height: calc(100% - 66px);
  width: calc(100%);
  overflow-y: scroll;
  /* background-color: var(--main-bg-color); */
}

.groupMainContainer > h1 {
  font-weight: bold;
  text-align: center;
  /* color: whitesmoke; */
  padding-top: 8px;
}

.groupMainContainer > p {
  text-align: center;
}

.groupMainCardsContainer {
  height: 100%;
  width: 100%;
  /* background-color: whitesmoke; */
  padding: 32px;
  margin-bottom: 64px;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: min-content;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.whatCategoriesContainer {
  width: 100%;
  height: 60px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1150px) {
  .groupMainContainer {

  }
  .groupMainCardsContainer {
    padding: 16px;
  }
}

@media only screen and (max-width: 850px) {
  .groupMainContainer {

  }
  .groupMainCardsContainer {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    padding: 8px;
  }
  .groupMainContainer > p {
    padding: 16px;
  }
}