:root {
  --main-bg-color-transparent: #222a2ec4;
  --main-bg-color: #2b353a;
  --main-bg-dark-color: #232b30;
  --main-bg-light-color: #38454b;
  --main-bg-lightDark-color: #313d42;
  --main-bg-lighter-color: #767e81;
  --main-bg-lighterDark-color: #8e9ba1;
  --main-yellow-color: #FFD71A;
  --main-light-yellow-color: #ffde3b;
  --main-dark-yellow-color: #e6c213;
  --main-blue-color: #1589C9;
  --main-dark-blue-color: #157cb4;
  --main-light-blue-color: #1592d4;
  --main-red-color: #EF2A30;
  --main-light-red-color: #db585c;
  --main-orange-color: #F47E2E;
  --main-grey-color: #4C5962;
  --main-dark-grey-color: #434f57;
  --main-light-grey-color: #5c6a74;
  --main-green-color: #4BB250;
  --main-light-green-color: rgba(82, 197, 88, 0.514);
  --main-dark-green-color: #329137;
  --main-text-dark: #0f0f0f;
  --main-text-mid: #696969;
  --main-text-light: whitesmoke;

  --main-light-border-color: rgb(228, 228, 228);
}

.mcRed {
 color: #c05c3d !important;
}
.mcOrange {
  color: #F47E2E !important;
}
.mcYellow {
  color: #FFD71A !important;
}
.mcGreen {
  color: #4BB250 !important;
}
.mcBlue {
  color: #1589C9 !important;
}
.mcGrey {
  color: #4C5962 !important;
}
.mcBlack {
  color: #040404 !important;
}

/* For Buttons */
.mcRedBG {
  background-color: #EF2A30;
 }
 .mcRedBG:hover {
  background-color: #ac2328;
 }
 .mcRedOrange {
   background-color: #d6542d;
 }
 .mcRedOrange:hover {
  background-color: #b64928;
}
 .mcOrangeBG {
  background-color: #F47E2E;
 }
 .mcOrangeBG:hover {
  background-color: #da6f28;
 }
 .mcYellowBG {
  background-color: #FFD71A !important;
 }
 .mcYellowBG:hover {
  background-color: #ddbc17 !important;
 }
 .mcGreenBG {
  background-color: #4BB250;
 }
 .mcGreenBG:hover {
  background-color: #419e46;
 }
 .mcBlueBG {
  background-color: #1589C9;
 }
 .mcBlueBG:hover {
  background-color: #1275aa;
 }
 .mcGreyBG {
  background-color: #4C5962;
 }
 .mcGreyBG:hover {
  background-color: rgb(52, 61, 66);
 }
 .mcLightBG {
   background-color: var(--main-bg-light-color);
 }
 .mcLightBG:hover {
   background-color: var(--main-bg-lightDark-color);
 }
 .mcLighterBG {
  background-color: var(--main-bg-lighter-color);
 }
 .mcLighterBG:hover {
  background-color: var(--main-bg-lighterDark-color);
 }

a, a:focus, a:active, a:hover, a:visited {
  text-decoration: none !important;
}

.fallBackContainer {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
 }

.fallBackContainer > img {
  width: 200px;
  height: 100px;
}

.mcTabA {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabA:hover:not(.active) {
  background-color: rgba(239, 42, 49, 0.25);
}
.mcTabA.active {
  background-color: #EF2A30;
}
.mcTabB {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabB:hover:not(.active) {
  background-color: rgba(244, 125, 46, 0.25);
}
.mcTabB.active {
  background-color: #F47E2E;
}
.mcTabC {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabC:hover:not(.active) {
  background-color: rgba(226, 188, 0, 0.25);
}
.mcTabC.active {
  background-color: rgb(226, 188, 0);
}
.mcTabD {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabD:hover:not(.active) {
  background-color: rgba(75, 178, 80, 0.25);
}
.mcTabD.active {
  background-color: #4BB250;
}
.mcTabE {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabE:hover:not(.active) {
  background-color: rgba(21, 138, 201, 0.25);
}
.mcTabE.active {
  background-color: #1589C9;
 }
.mcTabF {
  border-radius: 8px;
  margin-bottom: 4px;
}
.mcTabF:hover:not(.active) {
  background-color: rgba(76, 89, 98, 0.25);
}
.mcTabF.active {
  background-color: #4C5962;
}

.red {
  color: #EF2A30;
}

.loading-spinner {
  min-height: 200px;
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.loading-spinner > svg {
  height: auto;
  width: auto;
}
.loading-spinner > svg .fa-primary {
  color: whitesmoke;
  opacity: 1;
}
.loading-spinner > svg .fa-secondary {
  color: var(--main-bg-color);
  opacity: 1;
}

.loadError {
  width: 100%;
  text-align: center;
  margin: 16px;
  color: var(--main-red-color);
  font-weight: bold;
}

p, h1, h2, h3, h4, h5, h6 {
  font-family: proximaNova;
  color: #040404;
}

.standardButton {
  cursor: pointer;
  background-color: #1589C9;
  color: white;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  font-weight: bold;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 34px;
}
.standardButton:hover {
  background-color: rgb(18, 117, 170);
}

.squareButton {
  padding: 0.6em 0.6em;
}

.standardButtonWithoutColour {
  cursor: pointer;
  color: white;
  border-radius: 8px;
  padding: 0.3em 0.6em;
  font-weight: bold;
  min-height: 34px;
}

.smallVersion {
  font-size: small;
}

.redStandardError {
  color: #EF2A30;
  font-size: smaller;
}

.muteVersion {
  background-color: #4C5962;
}
.muteVersion:hover {
  background-color: rgb(63, 74, 82);
}

.link {
  cursor: pointer;
  color: #1589C9;
}
.link:hover {
  color:  rgb(18, 117, 170);
}

.editIconVersion {
  padding-right: 0.5em;
}

.redVersion {
  background-color: #EF2A30;
  border-color: #EF2A30;
}
.redVersion:hover {
  background-color: rgb(206, 36, 41);
  border-color: #EF2A30;
}

.largerErrorMessage {
  color: #EF2A30;
  font-size: 1em;
}

.spinnerThing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 66px);
}

.rowThumb {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.pillBadge {
  display: inline-block;
  font-size: small;
  font-weight: bold;
  margin-right: 5px;
  margin-left: 5px;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #1589C9;
  width: fit-content;
  color: white;
  padding: .25em .4em;
  border-radius: 10px;
}

.pillGreenVersion {
  font-size: 0.7em;
  background-color: green;
  margin-bottom: 0px;
}
.pillRedVersion {
  font-size: 0.7em;
  background-color: red;
  margin-bottom: 0px;
}

.comingSoonText {
  font-weight: bold;
  font-size: larger;
}

.largeTitle {
  font-size: medium;
  margin-bottom: 4px;
}
.smallTitle {
  font-size: small;
  margin: 0;
}

.filterSearchBarStandard {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.faSelectEventButton {
  /* color: #1589C9; */
  -ms-transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}
.faSelectEventButton:hover {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
  transform: rotate(90deg);
}
.addressTitle {
  font-size: small;
  margin-bottom: 0em;
}
.addressContainer {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto auto;
  grid-template-areas: 
  "topLeft top topRight"
  "bottomLeft bottom bottomRight";
}
.addressTopLeft {
  grid-area: topLeft;
}
.addressTop {
  grid-area: top;
}
.addressTopRight {
  grid-area: topRight;
}
.addressBottomLeft {
  grid-area: bottomLeft;
}
.addressBottom {
  grid-area: bottom;
}
.addressBottomRight {
  grid-area: bottomRight;
}

.icon:focus .clickIcon  {
  animation-name: bounce;
  display: inline-block;
  position: relative;
  -moz-animation: bounce 0.4s linear;
  -o-animation: bounce 0.4s linear;
  -webkit-animation: bounce 0.4s linear;
  animation: bounce 0.4s linear;
}

.iconContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.iconContainer > img {
  margin: 4px;
  height: 40px;
  width: 40px;
}
.iconLarge > img {
  margin: 8px;
  height: 56px;
  width: 56px;
}
.hoverOn {
  /* display: none; */
  box-shadow: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  top: -24px;
  font-size: x-small;
  display: inline-block;
  color: whitesmoke;
  background-color: var(--main-grey-color);
  z-index: 10;
  padding: 0.3em 0.6em;
  border-radius: 8px;
  font-weight: bold;
}
.iconImage:hover + .hoverOn {
  visibility: visible;
  position: absolute;
  top: -36px;
  opacity: 1;
  font-size: medium;
  white-space: nowrap;
  transition: opacity 0.3s, top 0.3s, font-size 0.3s;
}



/* Image Carousel */
.imageCarouselContainer {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: whitesmoke;
  border-radius: 8px;
  overflow: hidden;
}
.imageCarouselContainer > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 200px;
  object-fit: contain;
  transition: opacity 0.5s ease;
}
.imageCarouselButton {
  position: absolute;
  top: calc(50% - 15px);
  z-index: 6;
  color: whitesmoke;
}
.imageCarouselButton .fa-primary {
  fill: white;
}
.imageCarouselButton .fa-secondary {
  fill: #040404;
}
.imageCarouselNextButton {
  left: 8px;
}
.imageCarouselNextButton:hover {
  color: white;
}
.imageCarouselPreviousButton {
  right: 8px;
}
.imageCarouselPreviousButton:hover {
  color: white;
}

/* Progress Circle */
.svgProgressCircle {
  display: block;
  max-width: 100%;
  transform: rotate(-90deg);
}
.svgCircle {
  fill: none;
}
.svgCircleBG {
  fill: none;
}
.svgCircleText {
  font-size: 0.5em;
  /* text-anchor: middle; */
  fill: #000000;
  font-weight: bold;
  transform: rotate(90deg);
}
.progressCircleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.progressCircleContainer > p {
  font-weight: bold;
  font-size: small;
  margin: 0;
}



/* Multi String Input */
.multi-string-container {

}
.multi-string-input-button {
  display: flex;
  flex-direction: row;
}
.multi-string-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  height: 2em !important;
}
.multi-string-input:focus {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.multi-string-input:focus + .multi-string-button {
  box-shadow: 0px 0px 2px 1px var(--main-green-color);
}
.multi-string-button {
  padding: 0.2em 0.4em;
  height: 2em;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--main-green-color);
  font-weight: bold;
  color: whitesmoke;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.04);
}
.multi-string-values {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.multi-string-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.4em;
  background-color: var(--main-green-color);
  border-radius: 8px;
  margin: 4px;
}
.multi-string-value > p {
  margin: 0;
  margin-right: 8px;
  color: whitesmoke;
  font-weight: bold;
}
.multi-string-value > svg {
  color: whitesmoke;
  cursor: pointer;
}

/* Accordion */
.accordion-container {

}
.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgb(229,229,229);
  background: linear-gradient(260deg, rgba(239,239,239,1) 0%, rgba(242,242,242,1) 100%);
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  margin-bottom: 4px;
  transition: background ease 0.2s;
}
.accordion-title:hover {
  background: linear-gradient(100deg, rgba(239,239,239,1) 0%, rgba(242,242,242,1) 100%);
}
.accordion-title > p {
  margin: 0;
}
.accordion-content {
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height ease 0.5s, padding ease 0.5s, opacity ease 0.5s;
}
.accordion-content.active {
  padding: 0 16px;
  opacity: 1;
}


/* Animations */

.grow { transition: all .3s }
.grow:hover { transform: scale(1.05); }

.growIcon { transition: all .3s }
.growIcon:hover { transform: scale(1.3); }

.growSmall { transition: all .3s }
.growSmall:hover { transform: scale(1.01); }

@-webkit-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-moz-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-o-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@-ms-keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}
@keyframes bounce {
  0% { top: 0; }
  50% { top: -0.2em; }
  70% { top: -0.3em; }
  100% { top: 0; }
}