.inspireContainer {
  height: calc(100% - 66px);
  width: 100%;
  overflow-y: scroll;
}
.inspireHeader {
  height: fit-content;
}
.inspireHeader > p {
  text-align: center;
}
.inspireHeader > h1 {
  font-weight: bold;
  text-align: center;
  padding-top: 8px;
}
.inspireContent {
  height: 100%;
  width: 100%;
  padding-bottom: 32px;
}
.inspireCardContainer {
  height: 100%;
  width: 100%;
  padding: 16px 32px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(260px, 1fr));
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.videoCard {
  cursor: pointer;
  height: 260px;
  width: 300px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  overflow: hidden;
}
.videoCardVideoContainer {
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.videoCard > div > img {
  height: 200px;
  width: 100%;
  object-fit: cover;
  z-index: 10;
}
.videoCardBottom {
  width: 100%;
  height: 100%;
  background-color: var(--main-bg-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.videoCardBottom > a {
  color: whitesmoke;
  padding: 4px;
  margin-bottom: 0;
  font-size: large;
  text-align: center;
}
.videoCardBottom > p {
  color: whitesmoke;
  padding: 4px;
  margin-bottom: 0;
  font-size: large;
  text-align: center;
}
.videoCard:hover > div > a {
  color: whitesmoke;
  text-decoration: none;
}

.videoModalBackground {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.videoModal {
  position: absolute;
  top: calc(50% - 350px);
  left: calc(50% - 400px);
  /* max-height: 80%; */
  height: fit-content;
  width: 800px;
  border-radius: 8px;
  padding: 80px;
  padding-top: 56px;
  padding-bottom: 40px;
  color: whitesmoke;
  background: linear-gradient(to bottom right, var(--main-bg-light-color), var(--main-bg-dark-color));
  /* overflow-y: scroll; */
}
.videoModalTitle {
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}
.videoModalTitle > h1 {
  color: whitesmoke;
}
.videoModalVideo {
  width: 100%;
  height: 400px;
}
.videoModal > p {
  color: whitesmoke;
  margin-top: 8px;
  width: 100%;
  text-align: center;
}
.videoModalVideo > iframe {
  height: 100%;
  width: 100%;
}
.linkCopyButtonVideoModal {
  width: 100%;
  margin: 8px;
}
.linkCopyButtonVideoModal > button {
  margin: auto;
}


@media only screen and (max-width: 1150px) {
  .inspireHeader > h1 {
    padding-left: 8px;
    padding-right: 8px;
  }
  .videoModalTitle > h1 {
    font-size: large;
  }
  .videoModal {
    width: 95vw;
    height: fit-content;
    top: 10%;
    left: calc(50% - 47.5vw);
    padding: 8px;
    padding-top: 32px;
    max-height: 90%;
  }
  .videoModalVideo {
    width: 100%;
    /* height: fit-content; */
  }
  .videoModal > p {
    font-size: small;
  }
}