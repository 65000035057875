.supplierInfoContainer {
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  height: 100%;
}

.supplierInfoHeader {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--main-yellow-color);
}
.supplierTextIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.supplierInfoHeader > div > svg {
  margin-left: 32px;
  font-size: 2em;
  color: #444444;
}
.supplierInfoHeader > div > p {
  margin: 0;
  margin-left: 12px;
  font-size: larger;
  font-weight: bold;
  color: #040404;
}

.supplierInfoContent {
  position: relative;
  height: calc(100% - 40px);
  border-radius: 8px;
  overflow-y: scroll;
  padding: 16px;
  background-color: whitesmoke;
}

.organisationDashCardContainer {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: 
  'info logo'
  'social social'
  'bank bank'
  'sponsoring sponsoring'
  'requests requests';
  overflow-y: scroll;
  margin-bottom: 8px;
}

.orgCard {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 8px;
  color: #040404;
  border: 1px solid rgba(128, 128, 128, 0.2);
}

.orgCardA {
  grid-area: info;
}
.orgCardB {
  grid-area: logo;
}
.orgCardC {
  grid-area: bank;
}
.orgCardD {
  grid-area: social;
  margin: auto;
}
.orgCardE {
  grid-area: sponsoring;
}
.orgCardF {
  grid-area: requests
}

.orgCardB > img {
  width: 100%;
  max-height: 360px;
  object-fit: scale-down;
}


.orgTitle {
  font-size: small;
  margin-bottom: 0;
}
.supplierTitleSmall {
  font-weight: bold;
  font-size: small;
  margin-bottom: 0em;
}
.orgText {
  font-size: large;
  margin-bottom: 0;
}

.bankingInfoContainer {
  display: grid;
  grid-template-columns: 33% 34% 33%;
  grid-template-rows: auto auto;
  grid-template-areas: 
  "topLeft top topRight"
  "bottomLeft bottom bottomRight";
}
.bankingTopLeft {
  grid-area: topLeft;
}
.bankingTop {
  grid-area: top;
}
.bankingTopRight {
  grid-area: topRight;
}
.bankingBottomLeft {
  grid-area: bottomLeft;
}
.bankingBottom {
  grid-area: bottom;
}
.bankingBottomRight {
  grid-area: bottomRight;
}

.websiteIcon {
  text-decoration: none;
  color: #1589C9;
}
.websiteIcon:hover {
  text-decoration: none;
  color:  rgb(16, 104, 151);
}

.iconSpecific {
  margin: 4px;
}

.iconSpecific:hover {
  text-decoration: none;
}

.iconSpecific:visited {
  text-decoration: none;
}

.imageContainer {
  position: relative;
  height: 200px;
  width: auto;
  background-color: white;
  border-radius: 0.25rem;
  margin-bottom: 16px;
}
.supplierImage {
  border-radius: 0.25rem;
  position: absolute;
  object-fit: scale-down;
  /* width: 100%; */
  height: 100%;
}

.emptyDBContainer {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.supplierEditButton {
  /* position: absolute;
  top: 8px;
  right: 8px; */
}

.createNewOrganisationButton {
  width: 100%;
  text-align: end;
}
.createNewOrganisationButton > a > button {
  background-color: var(--main-yellow-color);
  color: #040404;
}


@media only screen and (max-device-width: 1150px) {
  .supplierInfoContent {
    padding: 8px;
  }

  .organisationDashCardContainer {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-row-gap: 12px;
    grid-template-areas: 
    'info'
    'logo'
    'social'
    'bank'
    'sponsors';
  }
}