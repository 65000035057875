@media only screen and (max-width: 600px) {
  .aboutBody {
    width: 90%;
  }
  .mcLogoAbout {
    width: 80%;
  }
}

@media only screen and (min-width: 600px) {
  .aboutBody {
    width: 50%;
  }
  .mcLogoAbout {
    width: 50%;
  }
}

.aboutContainer {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  height: auto;
  width: 100%;
  background-color: white;
  font-family: proximaNova;
}

.header {
  font-weight: bold;
  color: #4B5962;
}
.aboutText {
  /* color: #F47E2E */

}
.usText {
  /* color: #FFD819; */
}
.logoSeperator {
  width: 70%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.aboutBody {
 font-family: proximaNova;
 text-shadow: 2px 2px 5px 5px black;
 margin: auto;
 margin-bottom: 20px;
 /* font-weight: bold; */
 /* color: white; */
}
.aboutDivShared {
  width: 100%;
}

.seperator {
  width: 80%;
}

.logoRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.logoImage {
  width: 200px;
  height: 100px;
  object-fit: scale-down;
  margin-bottom: 20px;
}

/* .aboutDiv1 {
  background-color: #EF2A30;
}
.aboutDiv2 {
  background-color: #F47E2E;
}
.aboutDiv3 {
  background-color: #FFD819;
}
.aboutDiv4 {
  background-color: #4AB350;  
}
.aboutDiv5 {
  background-color: #1489C9;  
}
.aboutDiv6 {
  background-color: #4B5962;  
} */


/* 
#4B5962
#1489C9
#4AB350
#FFD819
#F47E2E
#EF2A30 */

.aboutOurPartners {
  color: #4B5962;
}
.iconAbout {
  width: 70%;
}

.mcLogoAbout {
  margin-top: 5em;
  margin-bottom: 30px;
}

.aboutBottomSpace {
  margin: 25px;
}