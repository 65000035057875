.title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-middle {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Error404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 93.6vh;
  width: 100vw;
  background-color: #232A2E;
  overflow: hidden;
  /* background: #003973;  fallback for old browsers */
  /* background: -webkit-linear-gradient(to right, #E5E5BE, #003973);  Chrome 10-25, Safari 5.1-6 */
  /* background: linear-gradient(to right, #E5E5BE, #003973); W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
@media only screen and (min-width: 600px) {
  .Container404 {
    font-size: 8em;
  }
  .MCRing {
    height: 100px;
    width: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .Container404 {
    font-size: 4em;
  }
  .MCRing {
    height: 60px;
    width: 60px;
  }
}

.Container404 {
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #232A2E;
  background-color: whitesmoke;
  padding: 25px;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 20px;
  line-height: 0em;
}
  .notFound {
  color: white;
}

.MCRing {
  margin-left: 0.1em;
  margin-right: 0.1em;
}

.pHeight {
  height: fit-content;
  margin: 0;
  font-weight: bold;
  font-family: proximaNova;
}

/* Terms and Conditions | Privacy policy */

.tcPage {
  height: auto;
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 80px;
  padding-right: 80px;
}

.tcContainer {
  background-color: whitesmoke;
  border-radius: 16px;
  font-family: proximaNova;
  padding-left: 160px;
  padding-right: 160px;
}

.mcLogoTC {
  margin: 0 auto;
  height: 100px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 30px;
}
.tcHeader {
  text-align: center;
  /* font-weight: bold; */
  font-size: x-large;
}
.tcSubHeader {
  font-weight: bold;
}
.tcContainer > p:not(.tcHeader) {
  font-size: 16px;
}
.tcContainer > p > span {
  font-weight: bold;
}
table, th, td {
  border: 1px solid black;
}
td {
  padding: 8px;
}
.tcTableHead {
  font-weight: bold;
}

@media only screen and (max-device-width: 1150px) {
  .tcPage {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 12px;
  }
  .tcContainer {
    padding-left: 16px;
    padding-right: 16px;
  }
  .makeSmallerText {
    font-size: 12px;
  }
}
