.dashboardContainer {
  height: calc(100% - 66px);
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  overflow: hidden;
}

.loginFormContainer {
  height: calc(100% - 66px);
  width: 100%;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginForm {
  border-radius: 16px;
  width: 350px;
  min-width: 300px;
  background-color: white;
  overflow: hidden;
  margin-bottom: 10%;
  box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.05);
}
.loginFormHeader {
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--main-bg-light-color);
}
.loginFormHeader > svg {
  margin-left: 16px;
  font-size: 2em;
  color: whitesmoke;
}
.loginFormHeader > p {
  margin: 0;
  margin-left: 12px;
  font-weight: bold;
  font-size: larger;
  color: whitesmoke;
}
.loginFormContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
}

.loginFormGroup {
  width: 100%;
}

.erroruserInfoContainer {
  height: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorUserInfo {
  background-color: #EF2A30;
  color: white;
  font-weight: bold;
  font-size: x-large;
  padding: 16px;
  border-radius: 12px;
}
.errorIconUserInfo {
  color: white;
  margin-right: 4px;
}
/* .errorIconUserInfo .fa-primary {
  fill: #a74913;
  opacity: 1;
}
.errorIconUserInfo .fa-secondary {
  fill: rgb(255, 182, 23);
  opacity: 1;
} */

.comingSoonContainer {
  height: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingSoonContainer > p {
  display: flex;
  align-items: center;
  padding: 20px;
  color: white;
  /* background-color: #1589C9; */
  border-radius: 8px;
  font-weight: bold;
}

.dbLayout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 80px calc(100% - 80px);
  grid-template-columns: 180px calc(100% - 180px);
  grid-template-areas: 
  "topLeft body"
  "sidebar body"
  /* "footer footer footer"; */
}
.dbTopLeft {
  grid-area: topLeft;
  background-color: var(--main-bg-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* font-weight: bold; */
  border-bottom: 1px solid var(--main-bg-color);
}
.dbTopLeft > p {
  margin-bottom: 0;
  color: whitesmoke;
}
.dbLeftBar {
  grid-area: sidebar;
  background-color: var(--main-bg-color);
  padding: 8px;
}
.dbBody {
  grid-area: body;
  padding: 20px;
  padding-left: 0px;
  /* overflow: hidden; */
  /* background-color: whitesmoke; */
  background-color: var(--main-bg-color);
}
.dbFooter {
  grid-area: footer;
  background-color: #4C5962;
  border-top: 1px solid #222B2E;
}

.dbUserImage {
  object-fit: cover;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid whitesmoke;
}

.headerIcons {
  margin-right: 16px;
}
.headerButton {
  height: 2em;
  width: 2em;
  margin-right: 8px;
  border-radius: 50%;
  overflow: hidden;
}
.dbHeaderIcon {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.dbIconFont {
  font-size: 1.2em;
}
.dbIconBg {
  background-color: white;
}
.dbIconBg:hover {
  background-color: #F47E2E;
}
.logoutIcon:hover {
  background-color: #FFD71A;
}

.dbFaBell .fa-primary {
  fill: #a74913;
  opacity: 1;
}
.dbFaBell .fa-secondary {
  fill: rgb(255, 182, 23);
  opacity: 1;
}

.dgFaSignOut .fa-primary {
  fill: #EF2A30;
}
.dgFaSignOut .fa-secondary {
  fill: #28334B;
}

.emptyDBContainer {
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centreText {
  text-align: center;
}
.accountTabHeaders {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.accountViewPanel {
  margin-right: 10px;
  margin-left: 10px;
}

.logoutButton {
  background-color: #EF2A30 !important;
  width: calc(100% - 16px);
  margin-left: 8px;
  margin-right: 8px;
}

.linkButtonText {
  text-decoration: none;
  color: white;
}

.linkButtonText:hover {
  text-decoration: none;
  color: white;
}

.modalFont {
  font-family: proximaNova;
}

.headerButtonsContainer {
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.headerButtonsContainerSingle {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
}
.headerButtonsContainerSingle > h3 {
  margin: 0;
  margin-left: 16px;
}
.headerButtonsContainerSingleRight {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
}

.cardsDashContainer {
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin-bottom: 32px;
}
.orgCardDash {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 200px;
  width: auto;
}
.orgCardDash > img {
  /* padding: 8px; */
  user-select: none;
  object-fit: cover;
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 4px solid whitesmoke;
}
.orgCardDash > p {
  font-weight: bold;
  font-size: larger;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
}
.orgCardGreen {
  background-color: var(--main-green-color);
}
.orgCardYellow {
  background-color: var(--main-yellow-color);
}
.orgTextWhite {
  color: whitesmoke;
}
.orgTextBlack {
  color: #404040;
}

.dashboardTab {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  height: 50px;
  width: 100%;
  /* display: block; */
  padding: 0 1em;
  color: whitesmoke;
  background-color: var(--main-bg-color);
  font-weight: bold;
  transition: all ease 0.2s;
}
.dashboardTab > svg {
  font-size: 1.2em;
  margin-right: .75em;
}
.dashboardTab > p {
  margin: 0em;
  color: whitesmoke;
}
/* #1589C9  */
.dashboardTab.active {
  /* background-color: #1589C9; */
}
/* rgba(21, 138, 201, 0.185) */
.dashboardTab:hover:not(.active) {
  /* background-color: rgba(21, 138, 201, 0.233); */

}



.genericModalBG {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 35;
}
.genericModal {
  position: relative;
  height: fit-content;
  max-height: 90vh;
  width: fit-content;
  max-width: 90vw;
  background-color: white;
  border-radius: 8px;
  overflow-y: scroll;
  padding-left: 64px;
  padding-right: 64px;
  z-index: 40;
  padding-bottom: 16px;
  padding-top: 16px;
}

.genericModalBGRoot {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 35;
}
.genericModalRoot {
  position: relative;
  height: fit-content;
  max-height: 95vh;
  min-height: 100px;
  width: fit-content;
  max-width: 90vw;
  min-width: 200px;
  border-radius: 8px;
  overflow-y: auto;
  padding-left: 64px;
  padding-right: 64px;
  z-index: 40;
  padding-bottom: 16px;
  padding-top: 16px;
  background-color: var(--main-bg-color);
  color: whitesmoke;
}

.modalCloseButtonLight {
  color: var(--main-green-color) !important;
}
.modalCloseButtonLight:hover {
  color: var(--main-dark-green-color) !important;
}

.modalCloseButtonDark {
  color: whitesmoke !important;
}
.modalCloseButtonDark:hover {
  color: white !important;
}

.modalCloseButton {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  color: #F5F5F5
}
.modalCloseButton:hover {
  color: #FFFFFF
}



@media only screen and (max-device-width: 1150px) {
  .dbBody {
    padding: 6px;
  }
  .dbLayout {
    grid-template-columns: 60px calc(100% - 60px);
  }
  .dbLeftBar {
    padding: 4px;
  }
  .dashboardTab {
    padding: 0;
  }
  .dashboardTab > svg {
    margin: auto;
    font-size: 1.2em;
  }
  .dashboardTab > p {
    margin: 0em;
  }
}


@media only screen and (max-device-width: 768px) {
  .genericModal {
    padding: 40px 16px;
    padding-bottom: 16px;
  }
  .genericModalRoot {
    padding: 40px 8px;
    padding-bottom: 16px;
  }
  .cardsDashContainer {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-bottom: 64px;
  }

  .loginForm {
    width: fit-content;
  }
}