#root {
  height: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  position: relative;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  font-family: proximaNova;
}

@font-face {
  font-family: proximaNova;
  font-weight: normal;
  src: url(../assets/fonts/Proxima-Nova-Regular.otf);
  font-display: swap
}
@font-face {
  font-family: proximaNova;
  font-weight: bold;
  src: url(../assets/fonts/Proxima-Nova-Bold.otf);
  font-display: swap
}
/* @font-face {
  font-family: proximaNova;
  src: url(../assets/fonts/Proxima-Nova-Thin.otf);
  font-weight: lighter;
}
@font-face {
  font-weight: bolder;
  font-family: proximaNova;
  src: url(../assets/fonts/Proxima-Nova-Extrabold.otf);
} */