.cnicTab {
  display: block;
  padding: .5rem 1rem;
  color: whitesmoke;
  border-radius: 8px;
  font-weight: bold;
}
.cnicTab.active {
  background-color: #1589C9;
  color: white;
}
.cnicTab:hover:not(.active) {
  color: white;
}

.cnicHeading {
  text-align: center;
  margin-top: 45.75px;
  font-weight: bold;
}

.cnicMustBeLoggedIn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}


.cnicLoggedInHeading {
  color: whitesmoke;
  padding: 12px;
  border-radius: 12px;
  font-weight: bold;
  font-size: x-large;
  text-align: center;
}

.cnicExclamationIcon {
  margin-right: 8px;
}
.cnicExclamationIcon .fa-secondary {
  stroke: white;
  stroke-width: 20;
}
.cnicExclamationIcon .fa-primary {
  fill: whitesmoke;
  opacity: 1;
}
.cnicExclamationIcon .fa-secondary {
  fill: #EF2A30;
  opacity: 1;
}


.orgCnicCard {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--main-blue-color);
  border-radius: 8px;
  height: 200px;
  width: auto;
}
.orgCnicCard > img {
  /* padding: 8px; */
  object-fit: cover;
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 4px solid whitesmoke;
}
.orgCnicCard > p {
  font-weight: bold;
  font-size: larger;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 0;
  color: whitesmoke;
}


.cnicContainer {
  width: 100%;
  height: calc(100% - 66px);
  overflow: hidden;
  background-color: whitesmoke;
}

.cnicCard {
  width: 80%;
  height: fit-content;
  max-height: 70%;
  text-align: center;
  font-family: proximaNova;
  margin: auto;
  background-color: white;
  border-radius: 16px;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
}
.cnicHeader {
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #4C5962;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.cnicBody {
  height: auto;
  padding: 2em;
  overflow-y: scroll;
}
.cnicFooter {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.cnicFooter > p {
  font-size: medium;
}
.cnicFooter > h6 {
  font-size: medium;
  margin: 0;
}

.tabRow {
  display: flex;
  justify-content: space-evenly;
}

.cnicSpinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchCnicContainer {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.searchCnicBox {
  border: 2px solid #1589C9;
  border-radius: 8px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 8px;
  height: 38px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

/* .searchCnicBox:focus {
  border-color: #017BFF;
} */
.cnicSearchIcon {
  font-size: 1em;
  cursor: pointer;
}
.cnicSearchInput {
  margin-left: 8px;
  margin-right: 8px;
  border: none;
}
.cnicSearchButton {
  height: 38px;
}

.cnicEventsList {
  padding-top: 40px;
  padding-bottom: 40px;
}
.cnicEvent {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* grid-template-columns: minmax(300px, 0.8fr) minmax(200px, 100px); */
  justify-items: center;
  align-items: center;
  grid-template-rows: 40px;
  padding-bottom: 10px;
}
.cnicItemText {
  grid-column-start: 1;
  grid-column-end: 2;
}
.cnicItemButton {
  grid-column-start: 2;
  grid-column-end: 3;
}
.cnicEventSeperator {
  width: 90%;
}

.headingCNIC {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.cnicAddContainer {
  margin-top: 50px;
  margin-bottom: 50px;
}
.cnicAddOptionsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: center;
  /* justify-content: center; */
  grid-row-gap: 2em;
}

.sampleDownload {
  padding: 6px 12px;
  cursor: pointer;
  border: 2px solid #1589C9;
  color: #1589C9;
  border-radius: 8px;
}
.sampleDownload a {
  color: #1589C9;
}
.sampleDownload:hover {
  background-color: rgba(21, 138, 201, 0.185);
}
.sampleDownload a:hover {
  color: #1589C9;
  text-decoration: none;
}

input[type="file"] {
  display: none;
}

.cnicFileInput{
  color: #1589C9;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border: 2px solid #1589C9;
  border-radius: 8px;
  margin-left: 8px;
}
.cnicFileInput:hover {
  background-color: rgba(21, 138, 201, 0.185);
}

.cnicUploadedCnicContainer {

}

.cnicNote {
  margin-top: 20px;
}
.cnicSuccess {
  color: #3EAC4A;
  font-size: small;
}

@media only screen and (max-device-width: 1150px) {
  .cnicCard {
    width: 90%;
  }
  .searchCnicBox {
    width: 80%;
  }
  .cnicSearchInput {
    width: 80%;
    margin: 0;
  }
  .cnicEvent {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }
  .cnicLoggedInHeading {
    width: 80%;
  }
  .cnicHeading {
    font-size: large;
    margin-top: 16px;
  }
}