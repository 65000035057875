.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.05); }

.homeViewContainer {
  position: absolute;
  top: 0;
  width: 100%;
}

.homeImage {
  width: 100%;
  height: 80vh;
  object-fit: cover;
  /* overflow: hidden; */
}
.homeImage0 {
  object-position: 50% 45%;
}
.homeImage1 {
  object-position: 70% 30%;
}
.homeImage2 {
  object-position: 60% 30%;
}
.homeImage3 {
  object-position: 35% 40%;
}
.homeImage4 {
  object-position: 20% 30%;
}
.homeImage5 {
  object-position: 40% 50%;
}
.homeImage6 {
  object-position: 49% 0%;
}
.homeImage7 {
  object-position: 50% 30%;
}
.homeImage8 {
  object-position: 70% 50%;
}
.homeImage9 {
  object-position: 55% 0%;
}

.homeImageNew1 {
  object-position: 49% 0%;
}
.homeImageNew2 {
  object-position: 70% 30%;
}
.homeImageNew3 {
  object-position: 60% 30%;
}
.homeImageNew4 {
  object-position: 35% 40%;
}
.homeImageNew5 {
  object-position: 20% 65%;
}
.homeImageNew6 {
  object-position: 40% 50%;
}

.spinnerHomeView {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-device-width: 1500px) and (min-device-width: 1000px) {
  .slideCaption {
    font-size: 3em;
  }
  .iconKeyImage {
    height: 5rem;
    width: 5rem;
  }
}

@media only screen and (max-device-width: 1000px) and (min-device-width: 600px) {
  .slideCaption {
    font-size: 2em;
  }
  .iconKeyImage {
    height: 4rem;
    width: 4rem;
  }
}

@media screen and (max-device-width: 600px) {
  .slideCaption {
    font-size: 1em;
  }
  .iconKeyImage {
    height: 4rem;
    width: 4rem;
  }
}

@media screen and (min-device-width: 1500px) {
  .slideCaption {
    font-size: 4em;
  }
  .iconKeyImage {
    height: 6rem;
    width: 6rem;
  }
}

.slideCaption {
  font-weight: bold;
  position: absolute;
  width: 100%;
  letter-spacing: 0.1em;
  transform: translate(-50%, -50%);
  z-index: 20;
  color: white;
  text-align: center;
  margin: auto;
  text-shadow: 1px 1px 4px black;
}

.captionNew1 {
  left: 50%;
  top: 75%;
}
.captionNew2 {
  left: 50%;
  top: 50%;
}
.captionNew3 {
  left: 50%;
  top: 75%;
}
.captionNew4 {
  left: 30%;
  top: 35%;
}
.captionNew5 {
  left: 50%;
  top: 50%;
}
.captionNew6 {
  left: 50%;
  top: 80%;
}

.caption0 {
  left: 50%;
  top: 65%;
}
.caption1 {
  left: 35%;
  top: 50%;
}
.caption2 {
  left: 40%;
  top: 70%;
}
.caption3 {
  left: 75%;
  top: 50%;
}
.caption4 {
  left: 75%;
  top: 50%;
}
.caption5 {
  left: 70%;
  top: 50%;
}
.caption6 {
  left: 50%;
  top: 75%;
}
.caption7 {
  left: 40%;
  top: 70%;
}
.caption8 {
  left: 40%;
  top: 80%;
}
.caption9 {
  left: 50%;
  top: 75%;
}
.carouselImage {
  height: 80vh;
  /* overflow: hidden; */
}

.projectInfoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.projectInfoDetails {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.projectInfoHeading {
  font-weight: bold;
}


.introText {
  width: 70%;
  text-align: center;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 64px;
  font-size: large;
}


.fontProxima {
  font-family: proximaNova;
}

.featuredText {
  font-size: small;
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 90%;
  margin: auto;
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
}
.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #F2F2F2;
}
.separator::before {
  margin-right: .25em;
}
.separator::after {
  margin-left: .25em;
}

.featuredLink {
  all: unset;
  height: fit-content;
}
.featuredLink:hover {
  all: unset
}
.featuredLink:active {
  all: unset
}
.featuredOrgs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* flex-shrink: 1; */
}
.featuredOrgCard {
  width: 280px;
  height: fit-content;
  text-align: center;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: hidden;
}
.featuredOrgImage {
  box-sizing: border-box;
  height: 200px;
  width: 100%;
  object-fit: contain;
  background-color: #ffffff;
  padding: 8px;
}
.featuredOrgText {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 0;
  background-color: var(--main-bg-color);
  height: 100%;
}
.featuredOrgText > span {
  color: whitesmoke;
  font-size: medium;
  font-weight: bold;
  text-align: center;
}
.featuredOrgDivider {
  margin: 0;
}
.featuredEventIcon {
  height: 100px;
  width: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}


.iconKeyContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.iconKey {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.iconKeyImage {
  margin-bottom: 12px;
}

.iconListHomeViewContainer {
  padding: 32px 64px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: auto;
}



.mapScreenshotContainer {
  display: block;
  width: 80vw;
  height: 20vh;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 60px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px -5px gray;
}
@media only screen and (max-device-width: 1150px) {
  .mapScreenshotContainer {
    height: 8vh;
  }

  .iconListHomeViewContainer {
    padding: 32px 16px;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  }
}
.mapScreenshot {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.homeViewFooter {
  width: 100%;
  height: fit-content;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #222A2E;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  font-size: 12px;
}
.homeViewFooter p {
  color: whitesmoke;
}
.homeViewFooterItem > p {
  margin: 0;
}
.homeViewFooterItemMiddle > p {
  margin: 0;
}
.homeViewFooterItemMiddle {
  display: flex;
  flex-direction: column;
}
.footerDash {
  width: 200px;
  border: 0.5px solid #ffffff;
  margin-top: 5px;
  margin-bottom: 10px;
}
.footHeader {
  font-size: 20px;
}
.aboutUsCustom {
  color: #F2F2F2;
}
.aboutUsCustom:hover {
  text-decoration: underline;
}
.footerIcon {
  color: #e7e7e7;
  font-size: 3em;
  margin: 5px;
}
.footerIcon:hover {
  color: #ffffff;
  cursor: pointer;
}


@media only screen and (max-device-width: 1150px) {
  .projectInfoDetails {
    font-size: small;
  }
  .introText {
    width: 85%;
  }
}