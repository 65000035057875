.noRation {
  /* vertical-align: middle;
  text-align: center; */
  /* width: 100%; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigBoi {
  height: 100%;
  width: 100%;
  padding: 20px;
  padding-top: 12px;
  overflow-y: scroll;
  background-color: var(--main-bg-color-transparent);
  color: whitesmoke;
  backdrop-filter: blur(6px);
}
.smallBoi {
  display: flex;
  flex-direction: column;
  width: fit;
  height: fit-content;
}
.smallBoi .eventInfoTitle {
  display: block;
  font-size: medium;
  font-weight: normal;
}
.smallBoi .eventContent {
  display: block;
  font-size: large;
  font-weight: bold;
}
.smallBoi a {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 16px;
  font-size: large;
  color: whitesmoke;
  font-weight: bold;
  width: fit-content;
}
.smallBoi div:hover a {
  text-decoration: none;
  color: #1589C9;
}
.orgLinkFromEvent {
  width: fit-content;
}
.smallBoi div svg {
  display: inline-block;
}
.smallBoi div:hover svg {
  color: #1589C9;
}

.searchBarSpace {
  margin: 0;
}

/* Used on map */
.imageContainerEvent {
  position: relative;
  height: 250px;
  width: auto;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--main-bg-color-transparent);
}
.imgEvent {
  object-fit: contain;
  width: 100%;
  height: 100%;
  /* transform: translate(-50%,-50%); */
}

.carousel-control-next, .carousel-control-prev {
  top: 50% !important;
}

.carouselImage {
  height: 100%;
  width: 100%;
}

.imageCarouselContainer {
  width: 100%;
  height: 300px;
  height: fit-content;
  margin-bottom: 12px;
}

.imageCarousel {
  height: fit-content;
  margin-top: 12px;
  border-radius: 12px;
  overflow: hidden;
}

/* .backButtonIcon {
  padding-right: 6px;
} */
.spaceBelow {
  margin-bottom: 12px;
}
.backButton {
  border: 0;
  text-align: left;
  padding-left: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 12px;
}

.updateDelete {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.eventModalDeleteButton {
  margin-right: 12px;
}

.editButtonEvent {
  text-decoration: none;
}