
/* #root {
  height: 100%;
  display: flex;
  flex-direction: column;
} */

.containerHome {
  flex-grow: 1;
  padding-left: 0 !important;
  margin: 0 !important;
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: calc(100% - 66px);
  width: 100vw;
}

/* .bottom {
  display: flex;
  height: 33vh;
  width: 100vw;
  align-items: stretch;
} */

.mapView {
  width: 70vw;
  height: calc(100%);
  /* flex-grow: 1; */
}

.eventListView {
  width: 30vw;
  height: calc(100% - 66px);
  position: absolute;
  right: 0;
  /* overflow-y: hidden; */
}
.eventListView p {
  color: whitesmoke;
}

.eventListView::-webkit-scrollbar {
  display: none;
}

.eventInfoView {
  flex-grow: inherit;
}

.filterProjectCategoryTitle {
  text-align: center;
  font-weight: bold;
  color: whitesmoke;
}
.projectMapFilterContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.projectMapFilterContainer > * {
  margin: 8px;
}
.projectMapFilter {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100px;
  padding: 8px;
  background-color: var(--main-bg-light-color);
  border-radius: 8px;
}
.projectMapFilter:hover {
  background-color: var(--main-bg-dark-color);
}
.projectMapFilter.active {
  background-color: var(--main-bg-dark-color);
}
.projectMapFilter > img {
  height: 44px;
  width: 44px;
  margin-bottom: 4px;
}
.projectMapFilter > p {
  color: whitesmoke;
  font-weight: bold;
  text-align: center;
  margin: 0;
  font-size: small;
}

.mapModalLayerTitle {
  margin: 0;
  color: whitesmoke;
  font-weight: bold;
  /* font-size: small; */
  text-align: center;
  margin-bottom: 4px;
  margin-top: 4px;
}
.MapViewLayerListContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}
.MapViewLayerListContainer > * {
  margin: 8px;
}
.MapLayerButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2px 4px;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--main-bg-light-color);
}
.MapLayerButton {
  width: 100%;
  height: 32px;
  color: whitesmoke;
  font-size: small;
  font-weight: bold;
  padding: 0.2em 0.8em;
}
.MapLayerButton:hover {
  background-color: #37454b;
}
.MapLayerButton.active {
  background-color: var(--main-bg-dark-color);
}
.toolTip {
  visibility: hidden;
  color: white;
  position: absolute;
  display: inline;
  text-align: center;
  background-color: #222a2edc;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin: 0;
  margin-top: 32px;
  border-radius: 8px;
  z-index: 1;
  font-weight: bold;
  font-size: small;
  opacity: 0;
  transition: opacity 0.2s, margin-top 0.2s;
}
.showToolTip {
  visibility: visible;
  opacity: 1;
  margin-top: 40px;
}

.mapInfoBox {
  background: #222A2E;
  /* padding: 12; */
}
.mapInfoBox > p {
  margin: 0;
  font-weight: normal;
  color: whitesmoke
}
.mapInfoBox > .mapInfoBoxHeading {
  font-size: small;
  color: whitesmoke
}
.mapInfoBox h6 {
  font-weight: bold;
  color: whitesmoke
}

.mapInfoProject {
  width: 300px;
  margin: auto;
}
.mapInfoProject > .mainProjectCardContainer > .mainProjectCard {
  border: none;
  cursor: default;
}
.mapInfoProject > .mainProjectCardContainer:hover img {
  transform: none;
}
.mapInfoProject > .mainProjectCardContainer > .mainProjectCard {
  box-shadow: none;
  padding-bottom: 0;
}
.mapInfoProject button {
  width: 100%;
  background-color: white;
  height: 44px;
  border-radius: 8px;
  margin-top: 8px;
  font-weight: bold;
  font-size: medium;
}
.mapInfoProject button:hover {
  background-color: whitesmoke;
}

.gm-ui-hover-effect > img {
  background-color: white !important;
  border-radius: 50%;
}
.gm-style-iw {
  max-width: 360px !important;
}
.gm-style-iw-d {
  width: fit-content;
  margin: auto;
  text-align: center;
}
.gm-style .gm-style-iw-c {
  background: #222A2E;
}
.gm-style .gm-style-iw-t::after {
  /* background-color: #222A2E; */
  background: #222A2E;
}
.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece  {
  background: #222A2E;
}

.lsoHouseContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
}
.lsoHouse {
  height: 50px;
  width: 50px;
}
.lsoHouseInfo p {
  margin-bottom: 0;
  font-size: smaller;
  font-weight: bold;
  color: whitesmoke
}
.lsoHouseInfoBottom {
  color: rgba(255, 255, 255, 0.3);
  position: absolute;
  height: 50px;
  width: 50px;
}
.lsoHouseInfoTop {
  color: whitesmoke;
  position: absolute;
  overflow: hidden;
  height: 50px;
  width: 50px;
  object-fit: fill;
  /* clip-path: inset(50% 0px 0px 0px); */
}
.lsoHouseInfo .mapInfoBoxHeading {
  font-size: x-small;
  font-weight: normal;
}

.mapLegend {
  height: auto;
  width: auto;
  position: absolute;
  bottom: 15px;
  left: 15px;
  padding: 8px;
  padding-right: 16px;
  background-color: #222a2edc;
  z-index: 2;
  border-radius: 8px;
}
.mapLegend p {
  margin: 0;
  font-weight: bold;
  color: whitesmoke;
  font-size: medium;
  margin-bottom: 4px;
}
.mapLegendRow p {
  margin-left: 4px;
}
#mapLegendHeader {
  font-size: large;
}
.mapLegendRow {
  display: flex;
  align-items: flex-start;
}
.mapLegendRoundIcons {
  height: 24px;
  width: 24px;
}
.lsoIconLegend {
  
} 

@media only screen and (max-width: 600px) {
  .eventListView {
    display: none;
  }
  .mapView {
    width: 100vw;
  }
}